var windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

jQuery(document).ready(function () {
  jQuery('.toggle-navigation').on('click', function (event) {
    jQuery('body').toggleClass('navigation-open');
    event.preventDefault();
  });

  if (jQuery('#testimonials .testimonials-wrapper').length) {
    var $testimonialsSlider = jQuery('#testimonials .testimonials-wrapper').slick({
      arrows: false,
      dots: true,
      infinite: true,
      autoplay: true,
      autoplaySpeed: 5000
    });
  }

  if (jQuery('#instagram-feed .slider-wrapper').length) {
    var $instagramFeedSlider = jQuery('#instagram-feed .slider-wrapper').slick({
      arrows: false,
      dots: false,
      infinite: true,
      autoplay: true,
      autoplaySpeed: 5000,
      slidesToShow: 10,
      slidesToScroll: 1,
      centerMode: true,
      centerPadding: '35px',
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 7
          }
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 4
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 2
          }
        }
      ]
    });

    jQuery('#instagram-feed').magnificPopup({
      delegate: 'a',
      type: 'image',
      gallery: {
        enabled: false
      }
    });
  }

  if (jQuery('#product-details .slider-wrapper').length) {

    jQuery('#product-details .slider-wrapper').magnificPopup({
      delegate: 'a', // child items selector, by clicking on it popup will open
      type: 'image',
      gallery:{enabled:true}
      // other options
    });

    var $productsSlider = jQuery('#product-details .slider-wrapper').slick({
      arrows: false,
      dots: true,
      infinite: false,
      autoplay: false,
      autoplaySpeed: 5000,
      draggable: false,
      swipe: false
    });
  }

  if (windowWidth >= 768) {
    jQuery('#product-details .images a').each(function (index) {
      var originalImage = jQuery(this).attr('href');
      jQuery(this).zoom({
        url: originalImage
      });
    });
  }

  if (jQuery('#coffee-image-slider').length) {
    jQuery('.coffee-image-slider').slick({
      infinite: true,
      dots: true,
      slidesToShow: 5,
      slidesToScroll: 1,
      prevArrow: '<button type="button" class="slide-arrow slide-prev"><i class="fa fa-caret-left" aria-hidden="true"></i></button>',
      nextArrow: '<button type="button" class="slide-arrow slide-next"><i class="fa fa-caret-right" aria-hidden="true"></i></button>',
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 4
          }
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 2
          }
        }
      ]
    });

    jQuery('.coffee-image-slider').magnificPopup({
      delegate: '.slide-item:not(.slick-cloned) a',
      type: 'image',
      gallery: {
        enabled: true
      }
    });
  }

  var testMobile;
  var isMobile = {
    Android: function () {
      return navigator.userAgent.match(/Android/i);
    },
    BlackBerry: function () {
      return navigator.userAgent.match(/BlackBerry/i);
    },
    iOS: function () {
      return navigator.userAgent.match(/iPhone|iPad|iPod/i);
    },
    Opera: function () {
      return navigator.userAgent.match(/Opera Mini/i);
    },
    Windows: function () {
      return navigator.userAgent.match(/IEMobile/i);
    },
    any: function () {
      return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows());
    }
  };

  function parallaxInit() {
    testMobile = isMobile.any();
    if (testMobile === null) {
      jQuery('.parallax').addClass('attach');
    }
  }

  parallaxInit();


  function getParameterByName(name, url) {
    if (!url) {
      url = window.location.href;
    }
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
    if (!results)
      return null;
    if (!results[2])
      return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  }

  if (windowWidth >= 768) {

  }

  if ('-webkit-appearance' in document.body.style || '-moz-appearance' in document.body.style || 'appearance' in document.body.style) {
    jQuery('.contact-us .gfield select').addClass('custom-select');
    jQuery('#product-details .buy-product select').addClass('custom-select');
  }

  // Scroll to section
  var navigationOffset = jQuery('header').innerHeight();
  var hash, tabs, tab;
  hash = window.location.hash;
  if (hash !== '') {
    setTimeout(function () {
      determineLocation(hash);
    }, 200);
  }

  jQuery('.navbar a, a.scroll, li.scroll a').on('click', function (event) {
    var linkLocation = jQuery(this).attr('href');
    var hashPosition = linkLocation.indexOf('#');
    var hashLocation;

    if (hashPosition !== -1) {
      hashLocation = linkLocation.substring(hashPosition, linkLocation.length);
    }

    return determineLocation(hashLocation);
  });

  function scrollToElement(element) {
    jQuery('html, body').animate({
      scrollTop: element.offset().top - navigationOffset
    }, 1000);
  }

  function determineLocation(hash) {
    console.log(jQuery(hash));
    if (jQuery(hash).length) {
      scrollToElement(jQuery(hash));
      return false;
    } else if (jQuery('a[href="' + hash + '"]').length) {
      jQuery('a[href="' + hash + '"]').trigger('click');
      tabs = jQuery('a[href="' + hash + '"]').parent().parent();
      scrollToElement(tabs);
      return false;
    } else {
      return true;
    }
  }

});

jQuery(window).on('load', function () {

});

jQuery(window).on('scroll', function () {

});

jQuery(window).on('resize', function () {

});